import CSSBeautifyComponent from "@jordibosch20/software_tools_package/dist/pages/CSSBeautify.component.js";
import styles from "./App.module.css";
import { geCSSBeautifyString } from "@jordibosch20/software_tools_package/dist/utils/css-beautify.js";
import { useAnalytics } from './useAnalytics';

function App() {
  useAnalytics();
  return (
    <div className={styles.AppWrapper}>
      <div className={styles.App}>
        <CSSBeautifyComponent title={"CSS beautify"} f={geCSSBeautifyString}></CSSBeautifyComponent>
      </div>
      <div className={styles.text}>
      <h3>Introduction</h3>
      <p>Sometimes, when debugging CSS, it is really useful to have it in a nicely written format. Usually compilers, webpacks and website builders transform it into minified format, to use less bytes and enhance network performance, but that has a clear drawback when we are trying to debug, inspect an error</p>
      <h3>Meet Our tool: The Instant CSS Beautifier</h3>
      <p>Imagine having the power to transform any piece of cluttered, minified CSS into a perfectly formatted and optimized stylesheet with just the click of a button. That's exactly what our CSS beautifier offers. No more sifting through lines of compacted code or scratching your head over which selector goes where. Our tool does the heavy lifting for you, ensuring your CSS is not just visually appealing but also primed for performance.</p>
      <h4>Key Features:</h4>
      <ul>
      <li><strong>One-Click Transformation:</strong> Paste your CSS, hit beautify, and voil&agrave; &ndash; clean, readable code.</li>
      <li><strong>Customization:</strong> Tailor the output to your coding standards, from indentation to bracket style.</li>
      </ul>
      <h3>How to Use the Instant CSS Beautifier</h3>
      <ol>
      <li><strong>Copy and Paste:</strong> Start by copying your minified or messy CSS code into the beautifier's input area.</li>
      <li><strong>Customize Settings:</strong> Choose your preferred formatting options, such as indentation type.</li>
      <li><strong>Beautify:</strong> With a single click, transform your code into a clean, optimized version.</li>
      <li><strong>Review and Use:</strong> Inspect the output for any adjustments you might need, then copy it back into your project.</li>
      </ol>
      <p></p>
      <h3>Can I paste confidential code?</h3>
      <div>
      <div><span>Your pasted text never leaves your browser. Our javascript website is just cline side and never does any communication with any server. You can be sure your text will never leave your browser/device.</span></div>
      </div>
      <p><strong>Can I use the beautifier for large CSS files?</strong><br />A: Absolutely! Our tool is designed to handle CSS files of all sizes, from small snippets to large stylesheets, ensuring efficient processing and optimization.</p>
        <div className={styles.links}>
          <h3>Other similar resoures that could be helpful</h3>

          <li><a href="https://check-json.com">JSON format checker</a></li>
          <li><a href="https://check-xml.com">XML Checker</a></li>
          <li><a href="https://check-yaml.com">YAML Checker</a></li>
          <li><a href="https://formatter-json.com">JSON formatter</a></li>
          <li><a href="https://add-delimiters.com">Add delimiters to sentences</a></li>
          <li><a href="https://convert-base.com">Convert base</a></li>
          <li><a href="https://convert-binary.com">Convert text/file to binary</a></li>
          <li><a href="https://convert-hexadecimal.com">Convert text/file to hexadecimal</a></li>
          <li><a href="https://convert-base64.com">Convert text/file to base64</a></li>
          <li><a href="https://css-beautify.com">CSS beautifier</a></li>
          <li><a href="https://html-beautify.com">HTML beautifier</a></li>
          <li><a href="https://javascript-beautify.com">Javascript beautifier</a></li>
          <li><a href="https://xml-beautify.com">XML beautifier</a></li>
          <li><a href="https://extract-email.com">Email extractor</a></li>
          <li><a href="https://extract-phone.com">Phone extractor</a></li>
          <li><a href="https://extract-url.com">URL extractor</a></li>
          <li><a href="https://json-2-csv.com">JSON to CSV online converter</a></li>
          <li><a href="https://json-to-yaml.com">JSON to YAML online converter</a></li>
          <li><a href="https://software-kit.com">Software tools</a></li>
          <li><a href="https://svg2css.com">SVG to CSS background </a></li>
          <li><a href="https://trim-text.com">Text trimmer</a></li>
          <li><a href="https://url-coder.com">URL encoder/decoder</a></li>
          <li><a href="https://yaml2json.com">YAML to JSON</a></li>
          <li><a href="https://csv-to-json.com">CSV to JSON</a></li>
          <li><a href="https://sort-text.com">Sort text</a></li>
          <li><a href="https://convert-casing.com">Convert casing</a></li>
          <br></br>
          <br></br>
          <br></br>
          <span>In case you have any <b>suggerence or found any bug</b> in our tools, please, feel free to email us at statisticskingdom @ gmail.com (without the spaces) and we will take care of it.</span>
        </div>
      </div>
    </div>
  );
}

export default App;